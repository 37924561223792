import React, { Fragment, useState } from 'react';
import styles from './styles.module.scss';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Row, Divider, Col } from '@element-softworks/daintree';

const Footer = () => {
    return (
        <Fragment>
            <footer className={styles.footerWrapper}>
                <div className={styles.footerContainer}>
                    <Grid>
                        <Row between={'md'}>
                            <Col lg={5} md={8} sm={10} xs={12}>
                                <div className={styles.footerContent}>
                                    <ul>
                                        <li>
                                            <Link to="/about">About us</Link>
                                        </li>
                                        <li>
                                            <Link to="/going-to-uni">Going to Uni?</Link>
                                        </li>
                                        <li>
                                            <Link to="/case-study">Case study</Link>
                                        </li>
                                        <li>
                                            <Link to="/become-a-pa">Become a PA</Link>
                                        </li>
                                        <li>
                                            <Link to="/blog">Blogs</Link>
                                        </li>
                                        <li>
                                            <Link to="/faq">FAQ</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">Contact us</Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy">Privacy Policy</Link>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={6} md={4} sm={2} xs={12}>
                                <div className={styles.social}>
                                    <div className={styles.footerImage}>
                                        <img src="/images/plants.svg" alt="plants" />
                                    </div>
                                    <div className={styles.socialMedia}>
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://www.facebook.com/LiveYouUK "
                                                    title="LiveYou facebook"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'facebook']}
                                                        className={styles.fontAwesomeIcon}
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.linkedin.com/company/liveyouuk"
                                                    title="LiveYou linkedIn"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'linkedin']}
                                                        className={styles.fontAwesomeIcon}
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="http://www.instagram.com/LiveYou_UK"
                                                    title="LiveYou instagram"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'instagram']}
                                                        className={styles.fontAwesomeIcon}
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </footer>
        </Fragment>
    );
};

export default Footer;
