import React, { Fragment, useState } from 'react';
import styles from './styles.module.scss';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@components/Button';
import { Grid } from '@element-softworks/daintree';
import SVG from 'react-inlinesvg';

const Navigation = ({ absoluteHeader }) => {
    const [navigationOpen, setNavigationOpen] = useState(false);
    const [desktopNavOpen, setDesktopNavOpen] = useState(false);
    return (
        <header
            className={`${styles.headerWrapper} ${absoluteHeader ? styles.absoluteHeader : ''}`}
        >
            <div className={styles.headerContent}>
                <Grid>
                    <div className={styles.headerContainer}>
                        <div className={styles.logoContainer}>
                            <Link to="/">
                                {absoluteHeader ? (
                                    <SVG src="/images/ly-logo-white.svg" alt="live you logo" />
                                ) : (
                                    <SVG src="/images/ly-logo.svg" alt="live you logo" />
                                )}
                            </Link>
                        </div>

                        <nav className={styles.desktopNavigation}>
                            <ul className={styles.desktopNavigationList}>
                                <li>
                                    <Link to="/about" activeClassName={styles.activeLink}>
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/going-to-uni" activeClassName={styles.activeLink}>
                                        Going to Uni?
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/case-study" activeClassName={styles.activeLink}>
                                        Case Study
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact" activeClassName={styles.activeLink}>
                                        Contact Us
                                    </Link>
                                </li>
                                <li>
                                    <div
                                        onClick={() => setDesktopNavOpen(!desktopNavOpen)}
                                        className={styles.moreButton}
                                    >
                                        More
                                        {desktopNavOpen ? (
                                            <FontAwesomeIcon
                                                icon={['fas', 'caret-up']}
                                                className={styles.fontAwesomeIcon}
                                                onClick={() => setNavigationOpen(!navigationOpen)}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={['fas', 'caret-down']}
                                                className={styles.fontAwesomeIcon}
                                                onClick={() => setNavigationOpen(!navigationOpen)}
                                            />
                                        )}
                                        <div
                                            className={`${
                                                desktopNavOpen
                                                    ? styles.desktopNavOpenWrapper
                                                    : styles.hidden
                                            }`}
                                        >
                                            <ul
                                                className={`${
                                                    desktopNavOpen
                                                        ? styles.moreNavigation
                                                        : styles.hidden
                                                }`}
                                            >
                                                <li>
                                                    <Link
                                                        to="/faq"
                                                        activeClassName={styles.activeLink}
                                                    >
                                                        FAQ
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/blog"
                                                        activeClassName={styles.activeLink}
                                                    >
                                                        Blogs
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/become-a-pa"
                                                        activeClassName={styles.activeLink}
                                                    >
                                                        Become a Pa
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </nav>

                        <div className={styles.navigationButton}>
                            <p className={styles.navigationText}>Navigation >></p>
                            <FontAwesomeIcon
                                icon={['fas', 'bars']}
                                className={styles.fontAwesomeIcon}
                                onClick={() => setNavigationOpen(!navigationOpen)}
                            />
                        </div>
                    </div>
                </Grid>
                <nav className={`${styles.hidden} ${navigationOpen ? styles.mobileNavMenu : ''}`}>
                    <div onClick={() => setNavigationOpen(false)} className={styles.closeNavBtn}>
                        <FontAwesomeIcon
                            icon={['fas', 'times']}
                            className={styles.fontAwesomeIcon}
                        />
                    </div>
                    <div className={styles.navigationContent}>
                        <ul className={styles.menuList}>
                            <li>
                                <Link to="/" activeClassName={styles.activeLink}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to="/about" activeClassName={styles.activeLink}>
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link to="/going-to-uni" activeClassName={styles.activeLink}>
                                    Going to Uni?
                                </Link>
                            </li>
                            <li>
                                <Link to="/blog" activeClassName={styles.activeLink}>
                                    Blogs
                                </Link>
                            </li>
                            <li>
                                <Link to="/faq" activeClassName={styles.activeLink}>
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link to="/case-study" activeClassName={styles.activeLink}>
                                    Case Study
                                </Link>
                            </li>
                            <li>
                                <div>
                                    <Link to="/contact" activeClassName={styles.activeLink}>
                                        Contact Us
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <Link to="/become-a-pa" activeClassName={styles.activeLink}>
                                    Become a PA
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Navigation;
